import call from "../../../../service/http";
import ContactConstants from "./ContactConstants";

export default {
  state: {
    contacts: [],
  },
  mutations: {
    SET_CONTACTS: (state, payload) => {
      state.contacts = payload;
    },
  },
  getters: {
    contacts: (state) => state.contacts,
  },
  actions: {
    /* get contacts */
    getContacts: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", ContactConstants.contacts)
        .then((res) => {
          commit("SET_CONTACTS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    saveContact: ({ commit, dispatch }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", ContactConstants.contacts, data)
        .then(() => {
          dispatch("getContacts");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    removeContact: ({ commit, dispatch }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", ContactConstants.remove, payload)
        .then(() => {
          dispatch("getContacts");
          commit("SET_LOADING", false, { root: true });
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
