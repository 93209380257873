import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VDialog,{attrs:{"persistent":"","width":"500px"},model:{value:(_vm.customerStatementReportdialog),callback:function ($$v) {_vm.customerStatementReportdialog=$$v},expression:"customerStatementReportdialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Customer Statement Report")])]),_c(VCardText,[_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.customerStatementReport.startDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.customerStatementReport, "startDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.customerStatementReport, "startDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.customerStatementReport.startDate),callback:function ($$v) {_vm.$set(_vm.customerStatementReport, "startDate", $$v)},expression:"customerStatementReport.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.customerStatementReport.startDate),callback:function ($$v) {_vm.$set(_vm.customerStatementReport, "startDate", $$v)},expression:"customerStatementReport.startDate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.customerStatementReport.startDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VMenu,{ref:"menu1",attrs:{"close-on-content-click":false,"return-value":_vm.customerStatementReport.endDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.customerStatementReport, "endDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.customerStatementReport, "endDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.customerStatementReport.endDate),callback:function ($$v) {_vm.$set(_vm.customerStatementReport, "endDate", $$v)},expression:"customerStatementReport.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.customerStatementReport.endDate),callback:function ($$v) {_vm.$set(_vm.customerStatementReport, "endDate", $$v)},expression:"customerStatementReport.endDate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.customerStatementReport.endDate)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.customerStatementReportdialog = false}}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"blue darken-1","outlined":""},on:{"click":_vm.viewStatement}},[_vm._v("view statment")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }