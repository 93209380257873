import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height d-flex flex-row align-center",staticStyle:{"background-color":"#dcdcdc"},attrs:{"fluid":"","color":"#26c6da"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}}},[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c(VSheet,{staticClass:"pa-10",attrs:{"elevation":"2","rounded":"xl"}},[_c(VRow,[(_vm.session)?_c(VAlert,{staticClass:"ml-6",attrs:{"dark":"","outlined":"","dense":"","border":"left","type":"info"}},[_vm._v(" "+_vm._s(_vm.session)+" ")]):_vm._e(),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,{attrs:{"flat":""}},[_c(VImg,{staticClass:"mb-5",attrs:{"src":("data:image/png;base64," + (_vm.$store.getters['Setup/companyInformation'].logo)),"height":"100","contain":""}}),_c(VCardTitle,{staticClass:"d-flex flex-row justify-center font-weight-black"},[_vm._v(" LOG IN ")]),_c(VCardSubtitle,{staticClass:"text-center font-weight-black"},[_vm._v(" For existing "+_vm._s(_vm.caption.login_caption)+" with active accounts ")]),_c(VCardText,{staticClass:"text-justify mt-2 pb-0"},[(_vm.$store.getters['Auth/alert'].status)?_c(VAlert,{staticClass:"mb-10",attrs:{"outlined":"","dense":"","border":"left","type":_vm.$store.getters['Auth/alert'].status === 'success'
                      ? 'success'
                      : 'error'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Auth/alert"].message)+" ")]):_vm._e(),_c(VTextField,{ref:"email",attrs:{"dense":"","outlined":"","label":"Email Address","placeholder":"Enter your Email Address","persistent-placeholder":"","rules":_vm.rules.email},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c(VTextField,{ref:"password",attrs:{"dense":"","outlined":"","label":"Password","placeholder":"Enter your password","persistent-placeholder":"","rules":_vm.rules.password,"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.showPassword ? 'text' : 'password'},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)},"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})],1),_c(VBtn,{staticClass:"mt-n4 text-lowercase text-decoration-underline",attrs:{"text":"","color":"info","to":{ name: 'forgotPassword' }}},[_vm._v(" Forgot password?")]),_c(VCardActions,{},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary","block":""},on:{"click":_vm.login}},[_vm._v(" Login ")])],1),_c(VCol,{staticClass:"mt-n4",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center overline grey--text"},[_vm._v("OR")])]),_c(VCol,{staticClass:"mt-n10",attrs:{"md":"6"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"to":{ name: 'activate' },"color":"info","block":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" Activate Account ")])]}}])},[_c('span',[_vm._v(" For members who have not accessed the portal before ")])])],1),(_vm.caption.registration)?_c(VCol,{staticClass:"mt-n10",attrs:{"md":"6"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"to":{ name: 'categories' },"color":"secondary","block":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" Register As Member ")])]}}],null,false,2163290849)},[_c('span',[_vm._v("For new members applications")])])],1):_vm._e()],1)],1)],1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c(VCol,{attrs:{"cols":"12"}},[_c(VDivider)],1):_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VContainer,{staticClass:"fill-height"},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"justify-center font-weight-black mt-4"},[_vm._v(" Registered Members ")]),_c(VCardText,{staticClass:"mt-n6 justify-left"},[_c('br'),_c('ul',[_c('li',[_vm._v("Click "),_c('b',[_vm._v("activate account")]),_vm._v(" button")]),_c('li',{staticClass:"mt-1"},[_vm._v(" Enter your email address which "),_c('b',[_vm._v("should be the same")]),_c('br'),_vm._v(" as the one registered with "+_vm._s(_vm.caption.organisation_caption)),_c('b',[_vm._v(" (members only)")])]),_c('li',{staticClass:"mt-1"},[_vm._v(" Login to your email for an "),_c('b',[_vm._v("activation link")]),_vm._v(" sent to you. ")]),_c('li',{staticClass:"mt-1"},[_vm._v(" Click on the "),_c('b',[_vm._v("link")]),_vm._v(" sent to your email to set your password and confirm ")]),_c('li',{staticClass:"mt-1"},[_vm._v("Login to your "),_c('b',[_vm._v("portal")])])])]),(_vm.caption.registration)?_c('div',[_c(VCardTitle,{staticClass:"justify-center font-weight-black mt-4"},[_vm._v(" New Applicants ")]),_c(VCardText,{staticClass:"mt-n6 justify-left"},[_c('br'),_c('ul',[_c('li',[_vm._v("Click the "),_c('b',[_vm._v(" Register As Member")]),_vm._v(" button")]),_c('li',{staticClass:"mt-1"},[_vm._v(" Follow the process to register and create an account ")])])])],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }