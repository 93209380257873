import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#A02642",
        secondary: "#F16724",
        accent: "#654343",
        error: "#b71c1c",
        info: "#2196F3",
        success: "#9F232D",
        warning: "#FFC107",
        BackTheme: "#E5E7E9",
        BadgeTheme: "#FFD700",
      },
    },
  },

  icons: {
    iconfont: "md",
  },
});
