import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-6",attrs:{"flat":""}},[_c(VRow,[_c(VCol,[_c(VDataTable,{staticClass:"elevation-1 mx-2",attrs:{"headers":_vm.headers,"items":_vm.checkList,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.checkListType",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('h4',{staticClass:"heading"},[_vm._v(_vm._s(item.checkListType))]),_c('span',{staticClass:"subtitle-2 grey--text"},[_vm._v(_vm._s(item.description))])])]}},{key:"item.pass",fn:function(ref){
var item = ref.item;
return [_c('td',[_c(VIcon,{attrs:{"color":item.pass ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(item.pass ? "mdi-check" : "mdi-alert")+" ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"small":"","disabled":item.pass,"to":{
              name: 'component',
              params: { name: item.checkListType },
            },"color":"secondary"}},[_vm._v(" Complete "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)]}}])})],1),_c(VCol,[_c(VCard,{staticClass:"mx-3",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"text-subtitle-2 justify-center"},[_vm._v("Help Section.")]),_c(VDivider),_c(VCardText,[_c('ul',{staticClass:"help-section-content"},[_c('li',[_vm._v(" You have "),_c('b',[_vm._v(_vm._s(_vm.completedCheckList.length)+"/"+_vm._s(_vm.checkList.length))]),_vm._v(" requirement(s) that are complete. ")]),_c('li',{staticClass:"mt-1"},[_vm._v(" To complete any incomplete requirement on the checklist (displayed on your left): "),_c('ul',[_c('li',{staticClass:"mt-1"},[_vm._v(" Click on the add action to complete a requirement. ")]),_c('li',{staticClass:"mt-1"},[_vm._v(" Once all requirements are complete, Confirm to Upgrade and finally click on continue. ")])])])])])],1)],1)],1),_c(VDivider,{staticClass:"mt-8"}),_c(VCardActions,[_c(VSpacer),_c('div',[_c(VCheckbox,{attrs:{"label":"I Confirm to upgrade"},scopedSlots:_vm._u([{key:"label",fn:function(){return undefined},proxy:true}]),model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c(VSpacer),_c(VBtn,{staticClass:"btn-continue",attrs:{"disabled":!_vm.checkbox || !_vm.isChecklistComplete,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Continue "),_c(VIcon,{attrs:{"right":""}},[_vm._v(" mdi-arrow-right ")])],1),_c(VSpacer)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }