<template>
  <v-container>
    <v-row>
      <v-col cols="6" md="3">
        <v-sheet
          rounded
          class="text-center d-flex flex-column justify-center align-center pa-5"
          elevation="1"
          dark
          color="secondary"
          style="cursor: pointer"
          @click="goToBilling('subscriptionList')"
        >
          <v-icon color="white" large> mdi-cash </v-icon>
          <h1 class="overline text-h5 mt-3">
            {{ balance ? balance.BalanceLCY : 0 | currencyFormat }}
          </h1>
          <p class="mt-3">Arrears</p>
        </v-sheet>
      </v-col>

      <v-col cols="6" md="3">
        <v-sheet
          rounded
          class="text-center d-flex flex-column justify-center align-center pa-5"
          elevation="1"
          dark
          color="info"
        >
          <v-icon color="white" large> mdi-account-check </v-icon>
          <h1 class="text-body-1 text-h7 mt-5">
            <!-- {{ member ? member.Status : "--" }} -->

            {{
              user.profile.InGoodStanding ? "In Good Standing" : "in arrears"
            }}
          </h1>
          <p class="mt-3">Status</p>
        </v-sheet>
      </v-col>

      <v-col cols="6" md="3">
        <v-sheet
          rounded
          class="text-center d-flex flex-column justify-center align-center pa-5"
          elevation="1"
          dark
          color="primary"
        >
          <v-icon color="white" large> mdi-school </v-icon>
          <h1 class="overline text-h5 mt-3" v-if="cpdAwardPoints">
            {{ cpdAwardPoints }}
          </h1>
          <h1 class="overline text-h5 mt-3" v-else>0</h1>
          <p class="mt-3">CPD points Earned</p>
        </v-sheet>
      </v-col>

      <v-col cols="6" md="3">
        <v-sheet
          rounded
          class="text-center d-flex flex-column justify-center align-center pa-5"
          elevation="1"
          dark
          color="brown darken-1"
        >
          <v-icon color="white" large> mdi-bell </v-icon>
          <h1 class="overline text-h5 mt-3">--</h1>
          <p class="mt-3">Notifications</p>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-sheet>
          <v-toolbar dense elevation="0">
            <v-toolbar-title class="subtitle-2"
              >General Information</v-toolbar-title
            >
            <v-spacer />
          </v-toolbar>
          <v-divider />
          <v-container>
            <v-card elevation="0">
              <v-img
                :src="profilePicture"
                height="200px"
                class="rounded"
                contain
              />
              <v-card-subtitle class="text-uppercase text-center">
                {{
                  `${userTitle}. ${user.profile.FirstName} ${user.profile.SecondName} ${user.profile.LastName} `
                }}
              </v-card-subtitle>
              <v-card-text>
                <v-simple-table dense v-slot:default>
                  <tbody>
                    <tr>
                      <td>Registration No.</td>
                      <td v-if="user.profile.MemberNo !== ''">
                        {{ user.profile.MemberNo }}
                      </td>
                      <td v-else>--</td>
                    </tr>
                    <tr>
                      <td>Category</td>

                      <td v-if="user.profile.MemberShipType !== ''">
                        {{ memberCategory }}
                      </td>
                      <td v-else>--</td>
                    </tr>
                    <tr>
                      <td>Discipline</td>
                      <td>{{ user.profile.Discipline }}</td>
                    </tr>
                    <tr>
                      <td>Current Workplace</td>
                      <td>{{ getCurrentWorkPlace }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-container>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="8">
        <v-sheet class="mb-5">
          <v-toolbar dense elevation="0">
            <v-toolbar-title class="subtitle-2"
              >My Membership Applications</v-toolbar-title
            >
            <v-spacer />
          </v-toolbar>
          <v-divider />
          <ApplicationListVue />
        </v-sheet>

        <v-sheet class="mb-5">
          <v-toolbar dense elevation="0">
            <v-toolbar-title class="subtitle-2"
              >Pending Payments</v-toolbar-title
            >
            <v-spacer />
          </v-toolbar>
          <v-divider />
          <v-container>
            <v-simple-table dense v-slot:default class="">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Date</th>
                  <th>Transaction type</th>
                  <th>Balance</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, i) in ledgers" :key="i">
                  <td>{{ line.externalDocumentNo }}</td>
                  <td>{{ formatDate(line.documentDate) }}</td>
                  <td>{{ line.documentType }}</td>
                  <td>{{ line.amountLCY | currencyFormat }}</td>
                  <td>
                    <v-btn
                      :to="{
                        name: 'invoice',
                        params: { code: line.externalDocumentNo },
                      }"
                      outlined
                      x-small
                      color="info"
                    >
                      Preview
                      <v-icon small right>mdi-printer</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AuthService } from "../../auth";
import DateMixin from "../../../mixins/DateMixin";
import SetupMixin from "../../setup/SetupMixin";
import { ApplicationMixin } from "../../application";
import ApplicationListVue from "@/modules/application/components/ApplicationList.vue";

export default {
  name: "Dashboard",
  mixins: [DateMixin, ApplicationMixin, SetupMixin],
  components: { ApplicationListVue },
  data: () => ({}),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Billing/getReceipt");
      v.$store.dispatch("Billing/getLedgers");
      // v.$store.dispatch("Profile/getCPDpoints", v.user.profile.MemberNo);
      v.getSetups(["getMembershipCategories"]);
      v.$store.dispatch("Profile/getExperience");
      v.$store.dispatch("Billing/getBalance");
      v.$store.dispatch("Dashboard/getGoodStanding", v.user.profile.ProfileID);
      v.$store.dispatch("Cpd/getCPDAWARDS", v.user.profile.MemberNo);
    });
  },

  computed: {
    balance() {
      return this.$store.getters["Billing/balance"][0];
    },

    ledgers() {
      return this.$store.getters["Billing/ledger"].filter((d) => {
        return (
          d.documentType === "Invoice" &&
          parseInt(d.remainingAmtLCY) > 0 &&
          d.externalDocumentNo.length > 0
        );
      });
    },

    user() {
      return AuthService.user;
    },

    cpdPoints() {
      return this.$store.getters["Profile/profileGetters"]("cpd");
    },

    // goodStanding() {
    //   const goodstanding = this.$store.getters["Dashboard/goodStanding"];
    //   return goodstanding ? goodstanding.goodStanding : "_ _";
    // },

    member() {
      return AuthService.user.profile.memberInformation.shift();
    },

    profilePicture() {
      let profile = AuthService.user.profile;

      return profile
        ? profile.picture
          ? `data:image/png;base64,${profile.picture}`
          : "/img/avatar.jpg"
        : "/img/avatar.jpg";
    },

    getCurrentWorkPlace() {
      let currentWorkPlace = this.$store.getters["Profile/experience"];
      if (currentWorkPlace !== undefined) {
        currentWorkPlace = currentWorkPlace.find((el) => el.Current);
        return currentWorkPlace !== undefined
          ? currentWorkPlace.NameOfFirm
          : "";
      } else {
        return "";
      }
    },

    memberCategory() {
      const member = this.membershipCategories.filter(
        (item) => item.Code === this.user.profile.MemberShipType
      )[0];
      return member ? member.Description : "";
    },

    cpdAwardPoints() {
      const points = this.$store.getters["Cpd/cpdGetters"]("cpd");
      const total_points = points.reduce((accumulated, currentValue) => {
        return accumulated + currentValue.pointAwarded;
      }, 0);
      return total_points;
    },
    userTitle() {
      let titles = JSON.parse(AuthService.userTitle).profile.profileTitle;
      return titles.length > 0 ? titles.map((el) => el.title).join(", ") : "";
    },
  },

  methods: {
    getMemberType(type) {
      return this.membershipCategories.find((el) => el.Code === type)
        .Description;
    },
    goToBilling(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style scoped>
td {
  font-size: 11px !important;
}
</style>
