import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-5"},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.application},scopedSlots:_vm._u([{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.Amount))+" ")]}},{key:"item.ApplicationDateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.ApplicationDateTime))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(["Committee Review", "Verified", "Board Review"].includes(item.status) ? "In Review" : item.status)+" ")]}},{key:"item.membershipComments",fn:function(ref){
var item = ref.item;
return [(!['New', 'Released', 'Open'].includes(item.status))?_c(VMenu,{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","outlined":"","disabled":item.membershipComments.length === 0}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-comment")]),_vm._v(" Comments ")],1)]}}],null,true)},[_c(VCard,[_c(VCardSubtitle,[_vm._v("Reviewer Comments")]),_c(VCardText,[_c(VListItemGroup,_vm._l((_vm.filterComments(
                  item.membershipComments
                )),function(comment,i){return _c(VListItem,{key:i,attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"overline"},[_vm._v(" "+_vm._s(comment.displayName)+" - "+_vm._s(_vm.formatDate(comment.commentDate, 6)))]),_c(VListItemSubtitle,[_vm._v(_vm._s(comment.comment))])],1)],1)}),1)],1)],1)],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(item.Submitted)?_c('div',[_c(VBtnToggle,[(_vm.profile.MemberNo === '')?_c(VBtn,{attrs:{"to":{ name: 'applicationDocument', params: { code: item.Code } },"outlined":"","small":""}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-file ")]),_vm._v(" File ")],1):_vm._e(),(
              _vm.application
                ? _vm.application[0].assignedMemberNo !== ''
                : '' && _vm.profile.MemberShipType !== 'GRADUATE'
            )?_c(VBtn,{attrs:{"to":{ name: 'Certificate', params: { code: item.Code } },"outlined":"","small":""}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-file ")]),_vm._v(" Certificate ")],1):_vm._e(),_c(VBtn,{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$root.routeTo({ name: 'invoice', params: { code: item.Code } })}}},[_c(VIcon,{attrs:{"color":"primary","left":"","small":""}},[_vm._v(" mdi-file ")]),_vm._v(" Invoice ")],1),(item.postedReceiptNo)?_c(VBtn,{attrs:{"outlined":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$root.routeTo({
                name: 'receipt',
                params: { code: item.postedReceiptNo },
              })}}},[_c(VIcon,{attrs:{"color":"secondary","left":"","small":""}},[_vm._v(" mdi-file ")]),_vm._v(" Receipt ")],1):_vm._e(),(item.status === 'Rejected')?_c(VBtn,{attrs:{"outlined":"","small":"","color":"secondary","to":{
              name: 'RejectionLetter',
              params: { code: item.Code },
            }}},[_c(VIcon,{attrs:{"color":"secondary","left":"","small":""}},[_vm._v(" mdi-file ")]),_vm._v(" Rejection Letter ")],1):_vm._e(),(item.status === 'Released')?_c(VBtn,{attrs:{"outlined":"","small":"","color":"secondary","to":{
              name: 'AcceptanceLetter',
              params: { code: item.Code },
            }}},[_c(VIcon,{attrs:{"color":"secondary","left":"","small":""}},[_vm._v(" mdi-file ")]),_vm._v(" Acceptance Letter ")],1):_vm._e(),(!item.postedReceiptNo)?_c(VBtn,{attrs:{"outlined":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.pay(item)}}},[_vm._v(" Pay Now "),_c(VIcon,{attrs:{"color":"secondary","right":"","small":""}},[_vm._v(" mdi-arrow-right ")])],1):_vm._e()],1)],1):_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","small":"","disabled":!_vm.isProfileComplete},on:{"click":function($event){return _vm.submit(item)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.profile.ProfileComplete ? "re-" : "")+"Submit "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-send")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isProfileComplete ? "Submit your application" : "Complete your profile"))])])],1)]}}])}),_c(VDialog,{attrs:{"width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"pa-7",attrs:{"tile":"","elevation":"5"}},[_c(VCardTitle,[_vm._v(" Verify Otp ")]),_c(VCardText,[(_vm.$store.getters['Application/alert'].status)?_c(VAlert,{staticClass:"mb-10",attrs:{"outlined":"","dense":"","border":"left","type":_vm.$store.getters['Application/alert'].status === 'success'
              ? 'success'
              : 'error'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Application/alert"].message)+" ")]):_vm._e()],1),_c(VForm,{ref:""},[_c(VCardText,[_c(VTextField,{ref:"otp_code",attrs:{"dense":"","outlined":"","label":"Otp Code","placeholder":"Enter Otp code","rules":_vm.rules.required},model:{value:(_vm.otp_code),callback:function ($$v) {_vm.otp_code=$$v},expression:"otp_code"}})],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.VerifyAppOTP}},[_vm._v(" Verify ")])],1)],1)],1),_c('Checkout')],1)}
var staticRenderFns = []

export { render, staticRenderFns }