import _ from "lodash";
import DashboardConstants from "./DashboardConstants";
import call from "../../service/http";
export default {
  namespaced: true,
  state: {
    customerStatementReport: "",
    customerStatementReportdialog: false,
    loading: false,
    state: {
      loading: false,
    },
    links: [
      {
        to: "/dashboard/summary",
        order: 1,
        icon: "mdi-gauge",
        title: "Dashboard",
        disabled: false,
      },
    ],
    actions: [],
    menus: [],
    companies: [],
    goodStanding: [],
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },

    SET_COMPANIES: (state, payload) => {
      state.companies = payload;
    },
    SET_GOOD_STANDING: (state, payload) => {
      state.goodStanding = payload;
    },

    SET_SET_MENU: (state, payload) => {
      if (_.findIndex(state.menu, payload) === -1) {
        state.menus.push(payload);
      }
    },

    SET_LINKS: (state, payload) => {
      if (_.findIndex(state.links, payload) === -1) {
        state.links.push(payload);
      }
    },

    SET_ACTIONS: (state, payload) => {
      if (_.findIndex(state.actions, payload) === -1) {
        state.actions.push(payload);
      }
    },

    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
    SET_CUSTOMER_STATMENT_DIALOG: (state, payload) => {
      state.customerStatementReportdialog = payload;
    },
    SET_CUSTOMER_STATEMENT_REPORT: (state, payload) => {
      state.customerStatementReport = payload;
    },
  },

  getters: {
    dashboardGetter: (state) => (key) => state[key],
    loading: (state) => state.loading,
    actions: (state) => state.actions,
    menus: (state) => state.menus,
    companies: (state) => state.companies,
    goodStanding: (state) => state.goodStanding,
    links: (state) => {
      return _.orderBy(state.links, (link) => link.order);
    },
    alert: (state) => state.alert,
  },

  actions: {
    resetPassword: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", DashboardConstants.password, data)
        .then(() => {
          commit("SET_ALERT", {
            status: "success",
            message: "Password Changed Successful",
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    saveSettings: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", DashboardConstants.notification, data)
        .then(() => {
          commit("SET_ALERT", {
            status: "success",
            message: "Setting Saved Successfully",
          });
          commit("SET_LOADING", false, { root: true });
          dispatch("Auth/user", null, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getGoodStanding({ commit }, payload) {
      commit("SET_LOADING", true, { root: true });
      call("get", DashboardConstants.GOODSTANDING(payload))
        .then((res) => {
          // commit("MUTATE", { state: "goodStanding", value: res.data.data[0] });
          commit("SET_GOOD_STANDING", res.data.data[0]);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    codeunit({ commit }, payload) {
      const { params, ...data } = payload;
      commit("SET_LOADING", true, { root: true });
      call("post", DashboardConstants.codeUnit(params), data)
        .then((res) => {
          commit("SET_CUSTOMER_STATEMENT_REPORT", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
