import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height d-flex flex-row align-center",staticStyle:{"background-color":"rgba(255, 255, 255, 0.65)"},attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"md":"6","offset-md":"3","sm":"12"}},[_c(VCard,{staticClass:"pa-5"},[_c(VCardTitle,{staticClass:"d-flex flex-row text-capitalize justify-center"},[_vm._v(" REQUIREMENTS FOR "+_vm._s(_vm.$route.params.code)+" APPLICATIONS ")]),_c(VCardText,{staticClass:"text-justify my-4 pb-0"},[_c(VList,{attrs:{"dense":"","flat":""}},[_c(VListItemGroup,_vm._l((_vm.checklist),function(item,i){return _c(VListItem,{key:i},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(_vm._s(("mdi-numeric-" + (i + 1) + "-circle")))])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('div',{staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(item.description)+" ")])])],1)],1)}),1)],1)],1),_c(VCardActions,{staticClass:"d-flex flex-row justify-end",class:_vm.$vuetify.breakpoint.mdAndDown ? 'd-flex flex-column mb-5' : ''},[_c('div',{staticClass:"d-flex flex-row"},[_c(VCheckbox,{staticClass:"mt-0 pt-0",model:{value:(_vm.tc),callback:function ($$v) {_vm.tc=$$v},expression:"tc"}}),_c('span',{staticClass:"info--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.termsDialog = true}}},[_vm._v(" I accept terms and conditions ")])],1),_c(VSpacer),_c(VBtn,{attrs:{"disabled":!_vm.tc,"to":{
              name: 'register',
              params: { type: _vm.$route.params.type, code: _vm.$route.params.code },
            },"color":"primary"}},[_vm._v(" continue "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1),(_vm.terms)?_c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.termsDialog),callback:function ($$v) {_vm.termsDialog=$$v},expression:"termsDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Terms and conditions ")]),_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.terms.termsAndConditions)}}),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.accept}},[_vm._v(" I accept ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }