import BillingConstants from "./BillingConstants";
import call from "../../service/http";

export default {
  namespaced: true,
  state: {
    ledger: [],
    balance: [],
    invoice: "",
    receipt: "",
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    SET_LEDGERS: (state, payload) => {
      state.ledger = payload;
    },
    SET_INVOICE: (state, payload) => {
      state.invoice = payload;
    },
    SET_RECEIPT: (state, payload) => {
      state.receipt = payload;
    },
    SET_BALANCE: (state, payload) => {
      state.balance = payload;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    ledger: (state) => state.ledger,
    invoice: (state) => state.invoice,
    receipt: (state) => state.receipt,
    alert: (state) => state.alert,
    balance: (state) => state.balance,
  },
  actions: {
    getLedgers: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", BillingConstants.ledger)
        .then((res) => {
          commit("SET_LEDGERS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getInvoice: ({ commit }, no) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", BillingConstants.invoice(no))
        .then((res) => {
          commit("SET_INVOICE", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getReceipt: ({ commit }, no) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", BillingConstants.receipt(no))
        .then((res) => {
          commit("SET_RECEIPT", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getBalance: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", BillingConstants.balance)
        .then((res) => {
          commit("SET_BALANCE", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
