<template>
  <v-container class="mt-5">
    <v-data-table :headers="headers" :items="ledgers" dense class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">My Statement</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "BillingList",

  data: () => ({
    headers: [
      { text: "Document No", value: "documentNo" },
      { text: "Document Type", value: "documentType" },
      { text: "Description", value: "description" },
      { text: "Amount", value: "amountLCY" },
      { text: "Due Date", value: "dueDate" },
      { text: "Posting Date", value: "postingDate" },
    ],
  }),

  mounted() {
    this.$store.dispatch("Billing/getLedgers");
  },

  computed: {
    ledgers() {
      return this.$store.getters["Billing/ledger"];
    },
  },
};
</script>

<style scoped></style>
