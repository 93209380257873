<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
  >
    <v-row>
      <v-col md="4" sm="12" offset-md="4">
        <v-card class="pa-5" :class="$vuetify.breakpoint.mobile ? 'mb-4' : ''">
          <v-img
            :src="`data:image/png;base64,${$store.getters['Setup/companyInformation'].logo}`"
            height="100"
            contain
            class="mb-5"
          />

          <v-card-title
            class="d-flex flex-row justify-center font-weight-black"
          >
            ACTIVATE
          </v-card-title>
          <v-card-subtitle class="text-center font-weight-black">
            For existing members with inactive accounts
          </v-card-subtitle>
          <v-card-text class="text-justify mt-4 pb-0">
            <v-alert
              outlined
              v-if="$store.getters['Auth/alert'].status"
              dense
              border="left"
              :type="$store.getters['Auth/alert'].status"
              class="mb-10"
            >
              {{ $store.getters["Auth/alert"].message }}
            </v-alert>

            <v-form ref="requestForm" v-model="isValidRequestForm">
              <div class="d-flex">
                <v-text-field
                  dense
                  outlined
                  label="Membership No"
                  placeholder="Enter your Membership No."
                  v-model="formData.memberNo"
                  ref="memberNo"
                  class="mr-2"
                  :rules="rules.required"
                />

                <v-btn @click="search" color="primary" class="" dark ripple>
                  <v-icon left>mdi-magnify</v-icon>
                  Search
                </v-btn>
              </div>

              <v-text-field
                dense
                outlined
                label="First Name"
                placeholder="Enter your First Name"
                v-model="formData.memberNameFirst"
                ref="memberNameFirst"
                disabled
              />

              <v-text-field
                dense
                outlined
                label="Last Name"
                placeholder="Enter your Last Name"
                v-model="formData.memberNameLast"
                ref="memberNameLast"
                disabled
              />

              <v-text-field
                dense
                outlined
                label="ID/Passport No."
                placeholder="Enter your ID/Passport No."
                v-model="formData.identificationDocumentNo"
                ref="IdentificationDocumentNo"
                :rules="rules.required"
              />

              <v-text-field
                dense
                outlined
                label="Mobile No."
                placeholder="Enter Mobile No."
                v-model="formData.phoneNo"
                ref="phoneNo"
                :rules="rules.phoneNo"
              />

              <v-text-field
                dense
                outlined
                label="Email Address"
                placeholder="Enter your Email Address"
                v-model="formData.eMailAddress"
                ref="eMailAddress"
                :rules="rules.email"
              />
            </v-form>
          </v-card-text>
          <v-card-actions
            :class="
              $vuetify.breakpoint.mdAndDown ? 'd-flex flex-column mb-5' : ''
            "
          >
            <v-btn @click="hasActivationCode" text> use code </v-btn>

            <v-spacer />

            <v-btn @click="requestActivation" color="primary">
              request activation
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="600" persistent>
      <v-card
        tile
        elevation="5"
        :loading="$store.getters['loading']"
        :disabled="$store.getters['loading']"
      >
        <v-card-title> Activate Account </v-card-title>

        <v-card-text>
          <v-alert text type="info">
            Enter your email and activation code below to activate your account.
            (Please check your email/sms for the activation code.)
          </v-alert>
          <v-alert
            outlined
            v-if="$store.getters['Auth/alert'].status"
            dense
            border="left"
            :type="
              $store.getters['Auth/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
          >
            {{ $store.getters["Auth/alert"].message }}
          </v-alert>
        </v-card-text>

        <v-form ref="passwordForm" v-model="isValidPasswordForm">
          <v-card-text>
            <v-text-field
              dense
              outlined
              label="Email Address"
              placeholder="Enter your Email Address"
              v-model="passwordForm.email"
              ref="eMailAddress"
              :rules="rules.email"
            />
            <!-- add extra check with ID for ICS -->
            <v-text-field
              dense
              outlined
              label="ID/Passport No."
              placeholder="Enter your ID/Passport No."
              v-model="passwordForm.identificationDocumentNo"
              ref="identificationDocumentNo"
            />
            <v-text-field
              dense
              outlined
              label="Activation Code"
              placeholder="Enter activation code"
              ref="otp_code"
              :rules="rules.required"
              v-model="passwordForm.otp_code"
            />

            <v-text-field
              dense
              outlined
              label="Password"
              placeholder="Enter your password"
              v-model="passwordForm.password"
              ref="password"
              :rules="rules.password"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            />

            <v-text-field
              dense
              outlined
              label="Confirm Password"
              placeholder="Enter your confirmation password"
              v-model="passwordForm.confirmPassword"
              ref="confirmPassword"
              :rules="rules.confirmPassword"
              :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showConfirmPassword ? 'text' : 'password'"
              @click:append="showConfirmPassword = !showConfirmPassword"
            />
          </v-card-text>
        </v-form>

        <v-card-actions>
          <v-btn @click="close" text> Close </v-btn>

          <v-spacer />

          <v-btn @click="activateAccount" color="primary">
            activate account
          </v-btn>
        </v-card-actions>
        <v-dialog
          persistent
          v-model="disclaimerDialog"
          max-width="40%"
          v-if="disclaimers"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Consent to Share Information
            </v-card-title>
            <v-card-text class="mt-6">
              {{ disclaimers.description }}
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                text
                @click="disclaimerDialog = false"
              >
                Disagree
              </v-btn>
              <v-btn color="error darken-1" @click="activate" text>
                Agree
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "../../../utils/eventBus";
import RouterMixin from "../../../router/RouterMixin";
import setupMixin from "../../setup/SetupMixin";

export default {
  name: "Activate",

  mixins: [RouterMixin, setupMixin],

  data: function () {
    return {
      dialog: false,
      disclaimerDialog: false,
      showPassword: false,
      showConfirmPassword: false,
      isValidPasswordForm: false,
      isValidRequestForm: false,
      passwordForm: {
        otp_code: "",
        password: "",
        confirmPassword: "",
        email: "",
        identificationDocumentNo: "",
      },
      formData: {
        memberNo: "",
        eMailAddress: "",
        memberNameFirst: "",
        memberNameLast: "",
        identificationDocumentNo: "",
        phoneNo: "",
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.params.code) v.hasActivationCode();
      v.getSetups(["getDisclaimers"]);
      if (v.$route.query.memberNo) {
        v.formData.memberNo = atob(v.$route.query.memberNo);
        v.search();
      }
    });
  },

  mounted() {
    EventBus.$on("activation-success", () => {
      this.hasActivationCode();
    });
  },

  computed: {
    rules() {
      return {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        required: [(v) => !!v || "Field is required"],
        // memberNameFirst: [(v) => !!v || "First name is required"],
        // memberNameLast: [(v) => !!v || "Last name is required"],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#~^()`.,;:{}_/%*?&])[A-Za-z\d@$!#~^()`.,;:{}_/%*?&]{8,16}$/.test(
              v
            ) ||
            "Password should contain: Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and at least one special character(@$!#~^()`.,;:{}_/%*?&)",
        ],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) => v === this.passwordForm.password || "Password does not match",
        ],
      };
    },

    items() {
      return this.$store.getters["Auth/profile"] || {};
    },

    disclaimers() {
      return this.$store.getters["Setup/disclaimers"].filter(
        (item) => item.disclaimerType === "Activation"
      )[0];
    },
  },

  methods: {
    requestActivation: function () {
      this.$refs.requestForm.validate();
      if (this.isValidRequestForm) {
        this.$store.dispatch("Auth/requestActivation", {
          ...this.formData,
          voterToken: this.token,
        });
      }
    },

    activateAccount: function () {
      this.$refs.passwordForm.validate();
      if (this.isValidPasswordForm) {
        this.disclaimers ? (this.disclaimerDialog = true) : this.activate();
      }
    },

    activate: function () {
      this.disclaimerDialog = false;
      if (this.isValidPasswordForm) {
        this.$store.dispatch("Auth/activate", {
          ...this.passwordForm,
        });
      }
    },

    hasActivationCode: function () {
      this.passwordForm.otp_code = this.$route.params.code
        ? this.decodeRoute(this.$route.params.code)
        : "";
      this.dialog = true;
    },

    close: function () {
      this.dialog = false;
      this.$store.commit("Auth/SET_ALERT", null);
    },

    search: function () {
      if (this.formData.memberNo)
        this.$store.dispatch(
          "Auth/searchMember",
          btoa(this.formData.memberNo.trim())
        );
    },
  },
  watch: {
    items() {
      const result = this.items;
      this.formData.memberNameFirst = result.FirstName;
      this.formData.memberNameLast = result.LastName;
    },
  },
};
</script>

<style scoped></style>
