<template>
  <v-container fluid>
    <v-card class="py-2 px-2" elevation="0" min-height="90vh">
      <v-card-title>
        <v-toolbar elevation="1">
          <v-btn outlined @click="$router.back()">
            <v-icon>mdi-arrow-left</v-icon>
            Go back
          </v-btn>
          <v-toolbar-title class="ml-2">
            <span class="headline">Customer Statement</span>
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pl-6">
        <iframe
          :src="`data:application/pdf;base64,${statement}`"
          style="border: none !important"
          width="1550"
          height="1000"
          title="Customer Statement report"
        >
        </iframe>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "viewCustomerStatement",
  mounted() {
    document.querySelector("embed").style.display = "block";
  },
  computed: {
    statement: {
      get() {
        return this.$store.getters["Dashboard/dashboardGetter"](
          "customerStatementReport"
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
