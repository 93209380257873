<template>
  <v-card class="mt-6" flat>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="checkList"
          class="elevation-1 mx-2"
          hide-default-footer
        >
          <template v-slot:item.checkListType="{ item }">
            <td>
              <h4 class="heading">{{ item.checkListType }}</h4>
              <span class="subtitle-2 grey--text">{{ item.description }}</span>
            </td>
          </template>

          <template v-slot:item.pass="{ item }">
            <td>
              <v-icon :color="item.pass ? 'success' : 'warning'">
                {{ item.pass ? "mdi-check" : "mdi-alert" }}
              </v-icon>
            </td>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              small
              :disabled="item.pass"
              :to="{
                name: 'component',
                params: { name: item.checkListType },
              }"
              color="secondary"
            >
              Complete
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <v-card class="mx-3" outlined>
          <v-card-title class="text-subtitle-2 justify-center"
            >Help Section.</v-card-title
          >
          <v-divider />
          <v-card-text>
            <ul class="help-section-content">
              <li>
                You have
                <b>{{ completedCheckList.length }}/{{ checkList.length }}</b>
                requirement(s) that are complete.
              </li>
              <li class="mt-1">
                To complete any incomplete requirement on the checklist
                (displayed on your left):
                <ul>
                  <li class="mt-1">
                    Click on the add action to complete a requirement.
                  </li>
                  <li class="mt-1">
                    Once all requirements are complete, Confirm to Upgrade and
                    finally click on continue.
                  </li>
                </ul>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="mt-8" />

    <v-card-actions>
      <v-spacer />
      <div>
        <v-checkbox v-model="checkbox" label="I Confirm to upgrade">
          <template v-slot:label> </template>
        </v-checkbox>
      </div>

      <v-spacer />
      <v-btn
        :disabled="!checkbox || !isChecklistComplete"
        @click="submit"
        class="btn-continue"
        color="primary"
      >
        Continue <v-icon right> mdi-arrow-right </v-icon></v-btn
      >
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { EventBus } from "../../../utils/eventBus";
import { AuthService } from "../../auth";
export default {
  name: "UpgradeChecklist",
  data() {
    return {
      checkbox: false,
      headers: [
        {
          text: "Item",
          align: "start",
          sortable: false,
          value: "checkListType",
        },
        { text: "Status", value: "pass" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Upgrade/certifiedChecklist", {
        category: v.$route.params.code,
        type: "Upgrade",
      });
      v.$store.dispatch("Upgrade/getPlans");
    });
  },

  mounted() {
    EventBus.$on("application-submitted", () => {
      this.$router.push({
        name: "upgradeList",
        // params: { code: this.applicationDetails.Code },
      });
    });
  },

  computed: {
    checkList() {
      return this.$store.getters["Upgrade/checklist"];
    },
    completedCheckList() {
      return this.checkList ? this.checkList.filter((c) => c.pass) : [];
    },
    upgrade() {
      return this.$store.getters["Upgrade/plans"][0];
    },
    isChecklistComplete() {
      let isComplete = true;
      this.$store.getters["Upgrade/checklist"].forEach((check) => {
        if (!check.pass) isComplete = false;
      });

      return isComplete;
    },
    user() {
      return AuthService.user;
    },
  },

  methods: {
    submit() {
      this.$confirm.show({
        title: `Submit Application ?`,
        text: `By clicking submit, you confirm that you have read the terms and conditions, that you understand them and that you agree to be bound by them.`,
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        onConfirm: () => {
          const data = {
            amount: this.upgrade.Amount,
            toCategory: this.upgrade.ToCategory,
            memberNo: this.user.profile.MemberNo,
            ProfileID: this.user.profile_id,
            requestedDate: "",
          };
          this.$store.dispatch("Upgrade/submitUpgrade", data);
        },
      });
    },
  },
};
</script>

<style scoped>
.help-section-content {
  color: #000;
  font-size: 13px;
}

.btn-continue {
  width: 30%;
}
</style>
