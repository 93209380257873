import ResponseCard from "./components/ResponseCard.vue";
import SearchLayout from "@/layouts/search/SearchLayout.vue"

export default [
  {
    path: "/referee-response",
    component: SearchLayout,
    children: [
      {
        path: "response/:user?/:code?",
        name: "ResponseCard",
        component: ResponseCard,
      },
    ],
  },
];
