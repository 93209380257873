export default {
  terms: "company/terms",
  requirements: "application/requirements",
  login: `auth/login`,
  register: `auth/register`,
  verify: `auth/verify`,
  verifyOtp: `auth/verify-otp`,
  sendOtp: (id) => `auth/send-otp?user_id=${id}`,
  passwordReset: `auth/password-reset`,
  password: `auth/password`,
  logout: `auth/logout`,
  user: `auth/user`,
  requestActivation: `auth/activation`,
  activate: `auth/activation/activate`,
  searchMember: (MemberNo) => `profile/search/member?MemberNo=${MemberNo}`,
};
