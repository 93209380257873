export default {
  methods: {
    emailMask: function (email) {
      if (!email) return "";

      const index = email.lastIndexOf("@");
      const prefix = email.substring(0, index);
      const postfix = email.substring(index);

      const mask = prefix
        .split("")
        .map(function (o, i) {
          if (i === 0 || i === index - 1) {
            return o;
          } else {
            return "*";
          }
        })
        .join("");

      return mask + postfix;
    },

    phoneMask: function (phoneNumber) {
      return phoneNumber ? phoneNumber.replace(/\d{5}$/, "*****") : "";
    },
    testPhoneMask: function (phoneNumber) {
      phoneNumber = phoneNumber.replace(/^(\d{0,4})(\d{0,3})/, "$1 $2");
      var prefix = phoneNumber.substr(0, phoneNumber.length - 6);
      var center = phoneNumber.substr(-4);
      var suffix = phoneNumber.substr(-2);
      var masked = center.replace(/\d/g, "*");
      var a = prefix + masked + suffix;
      console.log(a);
      return a;
    },
    memberNumberMask: function (MemberNo) {
      return MemberNo.replace(
        /^(\d{2})(\d{2})$/,
        (match, firstTwoDigits, lastTwoDigits) => {
          return "*".repeat(2) + lastTwoDigits;
        }
      );
    },
  },
 
};
