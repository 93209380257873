<template>
  <v-container>
    <v-row v-if="typeof document !== 'undefined' && document !== ''">
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="my-5">
          <v-card-title>
            <v-spacer />
            <v-btn
              small
              color="secondary"
              @click="downloadFile(document, fileName)"
            >
              <v-icon left>mdi-download</v-icon>
              Download
            </v-btn>
          </v-card-title>

          <pdf
            style="width: 100%"
            :src="`data:application/pdf;base64,${document}`"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="typeof document === 'undefined' || !document">
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="my-5" flat>
          <v-card-text>
            <v-alert dense border="left" type="success">
              Dear member, Kindly note that your certificate of good standing is
              dependent on having been
              <strong>fully paid up on your subscriptions</strong> and having
              <strong>attained the required CPD points</strong> as per the CPD
              policy. Additionally
              <strong>you should not have a pending disciplinary matter</strong
              >.If you have an issue, Kindly contact
              <strong>membership@ics.ke</strong> for assistance.
            </v-alert>
            <div v-if="goodStandingReasons">
              <ul>
                <li :key="index" v-for="(reason, index) in goodStandingReasons">
                  {{ reason }}
                </li>
              </ul>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="my-5">
          <v-card-title>
            <v-spacer />
            <v-skeleton-loader type="button" />
          </v-card-title>

          <div>
            <v-skeleton-loader type="card" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import RouterMixin from "../../../router/RouterMixin";
import FileMixin from "../../../mixins/FileMixin";
import { AuthService } from "../../auth";

export default {
  name: "Document",
  components: { pdf },
  mixins: [RouterMixin, FileMixin],
  data: () => ({}),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch(
        "License/certificateOfGoodStanding",
        v.decodeRoute(v.$route.params.code)
      );
    });
  },

  computed: {
    document() {
      return this.$store.getters["License/licenseGetters"](
        "certificateOfGoodStanding"
      )[0];
    },
    user() {
      return AuthService.user;
    },
    fileName() {
      const certName = "Good-Conduct-Certificate";
      const {
        profile: { FirstName, CustomerNo },
      } = this.user;
      return `${FirstName}-${CustomerNo}-${certName}`;
    },
    goodStandingReasons() {
      return this.user.profile?.goodStandingMessage?.split("\\");
    },
  },
};
</script>
