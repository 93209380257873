<template>
  <v-container fluid>
    <v-dialog v-model="customerStatementReportdialog" persistent width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Customer Statement Report</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="customerStatementReport.startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="customerStatementReport.startDate"
                      label="Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="customerStatementReport.startDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.menu.save(customerStatementReport.startDate)
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :return-value.sync="customerStatementReport.endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="customerStatementReport.endDate"
                      label="End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="customerStatementReport.endDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu1.save(customerStatementReport.endDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="customerStatementReportdialog = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" outlined @click="viewStatement"
            >view statment</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { AuthService } from "../../auth";
export default {
  name: "CustomerStatementReport",
  data() {
    return {
      menu: false,
      menu1: false,
      customerStatementReport: {
        startDate: "",
        endDate: "",
      },
    };
  },
  computed: {
    user() {
      return AuthService.user;
    },
    customerStatementReportdialog: {
      get() {
        return this.$store.getters["Dashboard/dashboardGetter"](
          "customerStatementReportdialog"
        );
      },
      set(value) {
        this.$store.commit("Dashboard/SET_CUSTOMER_STATMENT_DIALOG", value);
      },
    },
  },
  methods: {
    viewStatement() {
      this.customerStatementReport.customerNo = this.user.profile.MemberNo;
      this.customerStatementReport.params = {
        provider: "MAGNOLIA_CU",
        entity: "MembershipManager_GetCustomerStatement",
      };
      this.$store.dispatch("Dashboard/codeunit", this.customerStatementReport);
      this.customerStatementReport = {};
      this.$store.commit("Dashboard/SET_CUSTOMER_STATMENT_DIALOG", false);
      this.$router.push({ name: "customerStatement" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
