<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
  >
    <v-row>
      <v-col md="6" offset-md="3" sm="12">
        <v-card class="pa-5">
          <v-card-title class="d-flex flex-row text-capitalize justify-center">
            REQUIREMENTS FOR {{ $route.params.code }} APPLICATIONS
          </v-card-title>
          <v-card-text class="text-justify my-4 pb-0">
            <v-list dense flat>
              <v-list-item-group>
                <v-list-item v-for="(item, i) in checklist" :key="i">
                  <v-list-item-avatar>
                    <v-icon>{{ `mdi-numeric-${i + 1}-circle` }}</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      <div style="white-space: normal">
                        {{ item.description }}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>

          <v-card-actions
            class="d-flex flex-row justify-end"
            :class="
              $vuetify.breakpoint.mdAndDown ? 'd-flex flex-column mb-5' : ''
            "
          >
            <div class="d-flex flex-row">
              <v-checkbox v-model="tc" class="mt-0 pt-0"></v-checkbox>

              <span
                style="cursor: pointer"
                @click="termsDialog = true"
                class="info--text"
              >
                I accept terms and conditions
              </span>
            </div>

            <v-spacer />

            <v-btn
              :disabled="!tc"
              :to="{
                name: 'register',
                params: { type: $route.params.type, code: $route.params.code },
              }"
              color="primary"
            >
              continue
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="termsDialog" width="600" v-if="terms">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Terms and conditions
        </v-card-title>

        <v-card-text v-html="terms.termsAndConditions" />

        <!-- <iframe
          v-else
          width="100%"
          height="500px"
          :src="terms.termsAndConditions"
        /> -->

        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="accept"> I accept </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Signup",
  data: function () {
    return {
      tc: false,
      termsDialog: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Auth/getTerms");
      v.$store.dispatch("Checklist/getChecklist", {
        category: v.$route.params.code,
        type: "Application",
      });
    });
  },

  computed: {
    terms() {
      return this.$store.getters["Auth/terms"][0];
    },

    checklist() {
      return this.$store.getters["Checklist/checklist"];
    },
  },

  methods: {
    accept: function () {
      this.tc = true;
      this.termsDialog = false;
    },
  },
};
</script>

<style scoped></style>
