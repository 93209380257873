<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
  >
    <v-row>
      <v-col cols="12" :md="initial ? 8 : 12" :offset-md="initial ? 2 : 0">
        <v-card elevation="0" class="px-5">
          <v-card-title v-if="initial">Initial Profile</v-card-title>

          <v-toolbar dense elevation="0" class="mb-3" v-if="!initial">
            <v-spacer />
            <v-btn small color="primary" @click="edit = !edit" v-if="!edit">
              <v-icon left>mdi-account-edit</v-icon>
              Edit
            </v-btn>
          </v-toolbar>

          <alert />

          <v-row>
            <v-col md="4" sm="12" class="px-7">
              <v-card elevation="0">
                <v-img
                  :src="profilePicture"
                  height="200px"
                  class="rounded"
                  contain
                ></v-img>

                <v-card-subtitle class="text-center" v-if="editable">
                  Attach a passport Size colored photo
                </v-card-subtitle>

                <v-card-actions v-if="editable">
                  <v-file-input
                    outlined
                    dense
                    ref="Image"
                    type="file"
                    v-model="formData.Image"
                    :rules="rules.Image"
                    @change="attachProfilePicture"
                    accept="image/png, image/jpeg"
                    hint="png and jpeg images only"
                    persistent-hint
                  >
                    <template v-slot:label>
                      <div>Attach Photo <span class="red--text">*</span></div>
                    </template>
                  </v-file-input>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-divider vertical />

            <v-col md="8" sm="12">
              <v-form>
                <v-row dense>
                  <v-col md="6" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      @input="
                        (val) =>
                          (formData.LastName = formData.LastName.toUpperCase())
                      "
                      v-model="formData.LastName"
                      :rules="rules.LastName"
                      ref="LastName"
                    >
                      <template v-slot:label>
                        <div>Last Name <span class="red--text">*</span></div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light grey--text font-weight-light"
                          >Last Name
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >{{ formData.LastName }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <v-text-field
                      outlined
                      v-if="editable"
                      dense
                      v-model="formData.FirstName"
                      @input="
                        (val) =>
                          (formData.FirstName =
                            formData.FirstName.toUpperCase())
                      "
                      :rules="rules.FirstName"
                      ref="FirstName"
                    >
                      <template v-slot:label>
                        <div>First Name <span class="red--text">*</span></div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >First Name
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >{{ formData.FirstName }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.SecondName"
                      @input="
                        (val) =>
                          (formData.SecondName =
                            formData.SecondName.toUpperCase())
                      "
                      ref="SecondName"
                    >
                      <template v-slot:label>
                        <div>Other Names</div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Other Names</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.SecondName
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col :md="!editable ? '6' : '3'" cols="12" class="px-5">
                    <v-select
                      v-if="editable"
                      dense
                      outlined
                      :items="['Male', 'Female']"
                      v-model="formData.Gender"
                      :rules="rules.Gender"
                      ref="Gender"
                    >
                      <template v-slot:label>
                        <div>Gender <span class="red--text">*</span></div>
                      </template>
                    </v-select>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Gender</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.Gender
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col :md="!editable ? '6' : '3'" cols="12" class="px-5">
                    <v-autocomplete
                      v-if="editable"
                      dense
                      outlined
                      :items="titles"
                      :item-value="(item) => item.title"
                      :item-text="(item) => item.description"
                      v-model="profileTitle"
                      :rules="rules.Title"
                      ref="Title"
                      multiple
                    >
                      <template v-slot:label>
                        <div>
                          Title
                          <span
                            v-if="minorSettings.organisation_caption !== 'EBK'"
                            class="red--text"
                            >*</span
                          >
                        </div>
                      </template>
                    </v-autocomplete>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Title</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          <!-- {{ formData.Title }} -->
                          <span
                            v-for="(title, i) in formData.profileTitle"
                            :key="i"
                          >
                            {{
                              `${title.title} ${
                                i === formData.profileTitle.length - 1
                                  ? ""
                                  : ","
                              }`
                            }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col
                    :md="!editable ? '6' : '6'"
                    cols="12"
                    class="px-5"
                    v-if="minorSettings.practicing_firm"
                  >
                    <v-autocomplete
                      v-if="editable"
                      dense
                      outlined
                      :items="firms"
                      :item-value="(item) => item.code"
                      :item-text="(item) => item.description"
                      v-model="formData.memberPracticingFirm"
                      ref="memberPracticingFirm"
                    >
                      <template v-slot:label>
                        <div>Practicing Firm</div>
                      </template>
                    </v-autocomplete>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                        >
                          Practicing Firm
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formData.memberPracticingFirm }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <!-- vuetify date picker menu -->
                    <v-menu
                      v-if="editable"
                      ref="menu"
                      v-model="birthDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="formData.DateOfBirth"
                          :rules="rules.DateOfBirth"
                          ref="DateOfBirth"
                          id="field"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <div>
                              Date of Birth <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.DateOfBirth"
                        no-title
                        scrollable
                        :max="today"
                        @input="birthDateMenu = false"
                      />
                    </v-menu>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Date of Birth</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.DateOfBirth
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.IdentificationDocumentNo"
                      :rules="rules.IdentificationDocumentNo"
                      ref="IdentificationDocumentNo"
                    >
                      <template v-slot:label>
                        <div>
                          ID/Passport No. <span class="red--text">*</span>
                        </div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >ID/Passport No.</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.IdentificationDocumentNo
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <v-file-input
                      v-if="editable"
                      outlined
                      dense
                      accept="application/pdf"
                      hint="pdf documents only"
                      persistent-hint
                      :append-outer-icon="formData.ID ? 'mdi-download' : ''"
                      @click:append-outer="downloadFile(formData.ID)"
                      v-model="formData.IDImage"
                      :rules="rules.IDImage"
                      ref="IDImage"
                    >
                      <template v-slot:label>
                        <div>
                          Attach ID/Passport (certified)
                          <span
                            v-if="minorSettings.organisation_caption === 'EBK'"
                            class="red--text"
                          >
                            *
                          </span>
                        </div>
                      </template>
                    </v-file-input>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >ID/Passport Attachment</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          <v-btn
                            small
                            text
                            color="secondary"
                            @click="downloadFile(formData.ID)"
                          >
                            <v-icon left>mdi-download</v-icon>
                            Download
                          </v-btn>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <v-select
                      v-if="editable"
                      dense
                      outlined
                      :items="countries"
                      v-model="formData.Nationality"
                      :rules="rules.Nationality"
                      ref="Nationality"
                      item-value="code"
                      item-text="Nationality"
                    >
                      <template v-slot:label>
                        <div>Nationality <span class="red--text">*</span></div>
                      </template>
                    </v-select>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Nationality</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.Nationality
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <v-select
                      v-if="editable"
                      dense
                      outlined
                      :items="filteredCounties"
                      v-model="formData.county"
                      ref="County"
                      :item-value="(item) => item.CountyNo"
                      :item-text="(item) => item.countyName"
                    >
                      <template v-slot:label>
                        <div>
                          {{
                            minorSettings.countyField
                              ? "County of Residence"
                              : "County"
                          }}
                        </div>
                      </template>
                    </v-select>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                        >
                          {{
                            minorSettings.countyField
                              ? "County of Residence"
                              : "County"
                          }}</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{ countyName }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <v-select
                      v-if="editable"
                      dense
                      outlined
                      :items="filteredCities"
                      v-model="formData.City"
                      ref="City"
                      :item-value="(item) => item.City"
                      :item-text="(item) => item.City"
                      auto-select-first
                    >
                      <template v-slot:label>
                        <div>City/Town (Select From List)</div>
                      </template>
                    </v-select>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >City</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{ formData.City }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <v-select
                      v-if="editable"
                      dense
                      outlined
                      label="Discipline"
                      :items="disciplines"
                      item-text="discipline"
                      item-value="discipline"
                      v-model="formData.Discipline"
                      :rules="rules.Discipline"
                      ref="Discipline"
                    >
                      <template v-slot:label>
                        <div>Discipline <span class="red--text">*</span></div>
                      </template>
                    </v-select>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Discipline</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.Discipline
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col
                    md="6"
                    cols="12"
                    class="px-5"
                    v-if="
                      minorSettings.organisation_caption === 'EBK' && isProfile
                    "
                  >
                    <Specialization
                      :isField="true"
                      :editable="editable"
                      ref="specialization"
                    />
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <v-switch
                      v-if="editable"
                      v-model="formData.Disability"
                      ref="Disability"
                      inset
                      label="Any form of disability"
                      class="mt-0"
                    />

                    <v-text-field
                      v-if="formData.Disability && editable"
                      v-model="formData.PWDCardNo"
                      :rules="rules.PWDCardNo"
                      ref="PWDCardNo"
                      label="Add PWD Card Number"
                      outlined
                      dense
                    >
                      <template v-slot:label>
                        <div>
                          Add PWD Number<span class="red--text">*</span>
                        </div>
                      </template>
                    </v-text-field>

                    <v-textarea
                      v-if="formData.Disability && editable"
                      v-model="formData.DisabilityDescription"
                      :rules="rules.DisabilityDescription"
                      ref="DisabilityDescription"
                      @input="
                        (val) =>
                          (formData.DisabilityDescription =
                            formData.DisabilityDescription.toUpperCase())
                      "
                      outlined
                      label="Specify disability"
                      rows="2"
                    >
                      <template v-slot:label>
                        <div>
                          Specify disability <span class="red--text">*</span>
                        </div>
                      </template>
                    </v-textarea>

                    <v-list-item dense v-else three-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                        >
                          Disability
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          {{ formData.DisabilityDescription || "None" }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-if="formData.PWDCardNo">
                          {{ formData.PWDCardNo }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-card-actions class="d-flex flex-row justify-end" v-if="editable">
            <v-btn @click="saveProfile" color="primary">
              {{ initial ? "Create Profile" : "Save" }}
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-dialog v-model="errorDialog" max-width="400" persistent>
        <v-card>
          <v-card-title>Something went wrong</v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-list>
              <v-list-item v-for="(item, i) in errorMessages" :key="i">
                <v-list-item-icon class="mr-2">
                  <v-icon color="error" size="16"
                    >mdi-close-circle-multiple-outline</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  {{ item }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="errorDialog = false" color="primary" depressed
              >Try again</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import SetupMixin from "../../../setup/SetupMixin";
import { AuthService } from "../../../auth";
import { helper } from "../../../../utils";
import fileType from "file-type";
import Alert from "../../../../components/Alert";
import Specialization from "../specialization/Specialization";

export default {
  name: "Bio",
  components: { Alert, Specialization },
  mixins: [SetupMixin],
  data: function () {
    return {
      image_url: "",
      edit: false,
      birthDateMenu: false,
      today: new Date().toISOString().substr(0, 10),
      formData: {
        LastName: "",
        FirstName: "",
        SecondName: "",
        Gender: "",
        profileTitle: [],
        DateOfBirth: "",
        IdentificationDocumentNo: AuthService.user.id_number,
        IDImage: undefined,
        ID: "",
        Nationality: "KE",
        county: "",
        City: "",
        Discipline: "",
        Disability: false,
        DisabilityDescription: "",
        Image: undefined,
        PWDCardNo: "",
        memberPracticingFirm: "",
      },
      errorDialog: false,
      errorMessages: [],
    };
  },

  props: {
    initial: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.$store.dispatch("Profile/getBio");
    this.getSetups([
      "getCountries",
      "getCounties",
      "getDisciplines",
      "getTitles",
      "getCities",
      "getFirms",
    ]); //TODO Enable setups
  },

  computed: {
    rules() {
      return {
        LastName: [(v) => !!v || "Last Name is required"],
        FirstName: [(v) => !!v || "First Name is required"],
        // SecondName: [(v) => !!v || "Second Name is required"],
        DateOfBirth: [(v) => !!v || "Date of Birth is required"],
        IdentificationDocumentNo: [(v) => !!v || "ID/Passport is required"],
        Gender: [(v) => !!v || "Gender is required"],
        Title: [(v) => !!v || "Title is required"],
        Nationality: [(v) => !!v || "Nationality is required"],
        Discipline: [(v) => !!v || "Discipline is required"],
        Image: [
          (v) => {
            return this.editable &&
              AuthService.user.profile &&
              !AuthService.user.profile.picture
              ? !!v || "Profile Picture is required"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.size < 2000000 || "Profile Picture should be less than 2MB"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.type.includes("image") || "Attachment must be an image"
              : true;
          },
        ],
        IDImage: [
          (v) => {
            return this.editable &&
              this.formData.ID === "" &&
              this.minorSettings.organisation_caption === "EBK"
              ? !!v || "ID/passport attachment is required"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.size < 10000000 || "Attachment should be less than 10MB"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.type.includes("application/pdf") ||
                  "Attachment must be a Pdf with both sides"
              : true;
          },
        ],
        PWDCardNo: [
          (v) =>
            (!!v && this.formData.Disability) || "PWD Card Number is required",
        ],
        DisabilityDescription: [
          (v) =>
            (!!v && this.formData.Disability) ||
            "Disability Description is required",
        ],
      };
    },

    profileTitle: {
      get() {
        return this.formData.profileTitle.map((item) => {
          return item.title;
        });
      },
      set(value) {
        this.formData.profileTitle = value.map((item) => {
          return {
            title: item,
            description: this.titles.find((el) => el.title === item)
              .description,
          };
        });
      },
    },

    profilePicture() {
      let profile = AuthService.user.profile;

      if (this.image_url) return this.image_url;

      return profile
        ? profile.picture
          ? `data:image/png;base64,${profile.picture}`
          : "/img/avatar.jpg"
        : "/img/avatar.jpg";
    },

    bio() {
      return this.$store.getters["Profile/bio"];
    },

    editable() {
      return this.initial || this.edit;
    },

    filteredCounties() {
      return this.counties
        .filter((item) => item.countryCode === this.formData.Nationality)
        .sort((a, b) => a.countyName > b.countyName);
    },

    filteredCities() {
      return this.cities
        .filter((item) => item.County === this.countyName)
        .sort((a, b) => a.City > b.City);
    },

    isProfile() {
      return AuthService.user.profile !== null;
    },

    countyName() {
      const temp =
        typeof this.formData.county === "object"
          ? this.formData.county.CountyNo
          : this.formData.county;
      const county = this.counties.filter((item) => item.CountyNo === temp);
      return county.length !== 0 ? county[0].countyName : null;
    },

    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },

    minorSettings() {
      return this.settings.minor_settings
        ? JSON.parse(this.settings.minor_settings)
        : "";
    },
  },

  methods: {
    saveProfile: function () {
      let isValid = true;
      let errors = [];
      for (const key in this.formData) {
        if (this.$refs[key]) {
          isValid = isValid ? this.$refs[key].validate(true) : false;
          if (this.$refs[key]._data.errorBucket.length !== 0) {
            this.$refs[key]._data.errorBucket.forEach((item) => {
              errors.push(item);
            });
            this.errorDialog = true;
          }
        }
      }
      this.errorMessages = errors;

      if (!isValid) return;

      let meta = {
        MemberShipType: AuthService.user.category,
        Email: AuthService.user.email,
      };
      const data = { ...this.formData, ...meta };
      delete data.SearchName;
      if (data.ProfileID) {
        data.profileTitle = data.profileTitle.map((item) => {
          const existing = data.titles.find(
            (title) => title.title === item.title
          );
          return {
            title: item.title,
            description: item.description,
            profileID: data.ProfileID,
            sequenceID: existing ? existing.sequenceID : undefined,
          };
        });
      }
      data.profileTitle = JSON.stringify(data.profileTitle);
      this.$store
        .dispatch(
          `Profile/${this.initial ? "saveBio" : "updateBio"}`,
          helper.prepareFormData(data)
        )
        .then(() => {
          this.$refs.specialization.saveSpecField();
        });
    },

    attachProfilePicture: function () {
      this["image_url"] = URL.createObjectURL(this.formData["Image"]);
    },

    downloadFile: async function (attachment, filename = "ID.pdf") {
      const myBuffer = Buffer.from(attachment, "base64");
      await fileType.fromBuffer(myBuffer).then((res) => {
        const path = `data:${res.mime})};base64,${attachment}`;
        const element = document.createElement("a");
        element.setAttribute("href", path);
        element.setAttribute("download", `${filename}`);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      });
    },
  },

  watch: {
    bio: {
      handler: function () {
        if (this.bio.length > 0) {
          this.formData = { ...this.bio[0] };
          this.formData.titles = this.bio[0].profileTitle;
          this.formData.DateOfBirth =
            this.formData.DateOfBirth !== "0001-01-01"
              ? this.formData.DateOfBirth
              : "";
        }
      },
    },
  },
};
</script>

<style scoped>
.transform {
  text-transform: uppercase;
}
</style>
