<template>
  <v-container class="mt-5">
    <v-data-table :headers="headers" :items="AllUpgrades" dense>
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            dark
            class="mb-2"
            :to="{ name: 'UpgradeCard' }"
          >
            <v-icon>mdi-playlist-plus</v-icon>
            New Upgrade
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.membershipComments="{ item }">
        <v-menu
          offset-x
          v-if="!['New', 'Released', 'Open'].includes(item.status)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              :disabled="item.membershipComments.length === 0"
            >
              <v-icon left>mdi-comment</v-icon>
              Comments
            </v-btn>
          </template>

          <v-card>
            <v-card-subtitle>Reviewer Comments</v-card-subtitle>
            <v-card-text>
              <v-list-item-group>
                <v-list-item
                  two-line
                  v-for="(comment, i) in filterComments(
                    item.membershipComments
                  )"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title class="overline">
                      {{ comment.displayName }} -
                      {{
                        formatDate(comment.commentDate, 6)
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      comment.comment
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex">
          <v-btn
            v-if="item.postedReceiptNo"
            outlined
            small
            color="secondary"
            @click="
              $root.routeTo({
                name: 'receipt',
                params: { code: item.postedReceiptNo },
              })
            "
          >
            <v-icon color="secondary" size="17" left small> mdi-file </v-icon>
            Receipt
          </v-btn>

          <v-btn
            v-if="item.proFormaRef"
            outlined
            small
            class="ml-1"
            color="primary"
            @click="
              $root.routeTo({
                name: 'invoice',
                params: { code: item.code },
              })
            "
          >
            <v-icon color="primary" size="17" left>mdi-file-document</v-icon>
            proforma
          </v-btn>

          <v-btn
            v-if="!item.postedReceiptNo"
            outlined
            small
            color="secondary"
            @click="pay(item)"
          >
            Pay Now
            <v-icon color="secondary" size="17" right small>
              mdi-arrow-right
            </v-icon>
          </v-btn>

          <!-- upgrade report -->
          <v-btn
            outlined
            small
            color="secondary"
            class="ml-1"
            :to="{
              name: 'upgradeDocument',
              params: { code: item.code },
            }"
          >
            <v-icon color="secondary" size="17">mdi-file-compare</v-icon>
            Upgrade Report
          </v-btn>

          <!-- acceptance letter  -->
          <v-btn
            outlined
            small
            color="green"
            class="ml-1"
            v-if="item.status === 'Released'"
            :to="{
              name: 'acceptanceLetter',
              params: { code: item.code },
            }"
          >
            <v-icon color="green" class="mr-1" size="17">mdi-file-document-check</v-icon>
            Acceptance letter
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <Checkout />
  </v-container>
</template>

<script>
import { appUrl } from "../../../environment/environment";
import Checkout from "@kinetics254/paygate/src/views/Checkout.vue";
import DateMixin from "@/mixins/DateMixin";
export default {
  name: "UpgradeList",
  mixins: [DateMixin],
  components: {
    Checkout,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Code",
        align: "start",
        sortable: false,
        value: "code",
      },
      { text: "From Category", value: "fromCategory" },
      { text: "To Category", value: "toCategory" },
      { text: "Amount", value: "amount" },
      { text: "Period", value: "subsPeriod" },
      { text: "Status", value: "status" },
      {
        text: `Feedback from ${process.env.VUE_APP_ORGANIZATION}`,
        value: "membershipComments",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Upgrade/getUpgrades");
      v.$store.dispatch("Upgrade/getPlans");
    });
  },
  computed: {
    AllUpgrades() {
      return this.$store.getters["Upgrade/upgrades"];
    },
    upgrade() {
      return this.$store.getters["Upgrade/plans"][0];
    },
  },

  methods: {
    filterComments(comments) {
      return comments.filter((comment) => !comment.hiddenFromApplicant);
    },
    pay: function (data) {
      let payload = {
        payerID: data.ProfileID,
        sourceDocumentNo: data.code,
        description: "Upgrade Payment",
        amount: data.amount,
        accountNo: "",
        success_url: new URL(
          this.$router.resolve({ name: "upgradeList" }).href,
          appUrl
        ),
        fail_url: window.location.href,
      };

      console.log(payload);
      payload = btoa(JSON.stringify(payload));
      // window.location.href = new URL(`?payload=${payload}`, payGateUrl);
      this.$checkout.pay(payload);
    },
  },
};
</script>

<style scoped></style>
