<template>
  <v-container class="mt-5">
    <v-data-table :headers="headers" :items="application" class="elevation-1">
      <template v-slot:item.Amount="{ item }">
        {{ item.Amount | currency }}
      </template>

      <template v-slot:item.ApplicationDateTime="{ item }">
        {{ formatDate(item.ApplicationDateTime) }}
      </template>

      <template v-slot:item.status="{ item }">
        {{
          ["Committee Review", "Verified", "Board Review"].includes(item.status)
            ? "In Review"
            : item.status
        }}
      </template>

      <template v-slot:item.membershipComments="{ item }">
        <v-menu
          offset-x
          v-if="!['New', 'Released', 'Open'].includes(item.status)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              :disabled="item.membershipComments.length === 0"
            >
              <v-icon left>mdi-comment</v-icon>
              Comments
            </v-btn>
          </template>

          <v-card>
            <v-card-subtitle>Reviewer Comments</v-card-subtitle>
            <v-card-text>
              <v-list-item-group>
                <v-list-item
                  two-line
                  v-for="(comment, i) in filterComments(
                    item.membershipComments
                  )"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title class="overline">
                      {{ comment.displayName }} -
                      {{
                        formatDate(comment.commentDate, 6)
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      comment.comment
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>

      <template v-slot:item.actions="{ item }">
        <div v-if="item.Submitted">
          <v-btn-toggle>
            <v-btn
              :to="{ name: 'applicationDocument', params: { code: item.Code } }"
              outlined
              v-if="profile.MemberNo === ''"
              small
            >
              <v-icon left small> mdi-file </v-icon>
              File
            </v-btn>

            <v-btn
              v-if="
                application
                  ? application[0].assignedMemberNo !== ''
                  : '' && profile.MemberShipType !== 'GRADUATE'
              "
              :to="{ name: 'Certificate', params: { code: item.Code } }"
              outlined
              small
            >
              <v-icon left small> mdi-file </v-icon>
              Certificate
            </v-btn>

            <v-btn
              outlined
              small
              color="primary"
              @click="
                $root.routeTo({ name: 'invoice', params: { code: item.Code } })
              "
            >
              <v-icon color="primary" left small> mdi-file </v-icon>
              Invoice
            </v-btn>

            <v-btn
              v-if="item.postedReceiptNo"
              outlined
              small
              color="secondary"
              @click="
                $root.routeTo({
                  name: 'receipt',
                  params: { code: item.postedReceiptNo },
                })
              "
            >
              <v-icon color="secondary" left small> mdi-file </v-icon>
              Receipt
            </v-btn>

            <v-btn
              outlined
              small
              v-if="item.status === 'Rejected'"
              color="secondary"
              :to="{
                name: 'RejectionLetter',
                params: { code: item.Code },
              }"
            >
              <v-icon color="secondary" left small> mdi-file </v-icon>
              Rejection Letter
            </v-btn>

            <v-btn
              outlined
              small
              v-if="item.status === 'Released'"
              color="secondary"
              :to="{
                name: 'AcceptanceLetter',
                params: { code: item.Code },
              }"
            >
              <v-icon color="secondary" left small> mdi-file </v-icon>
              Acceptance Letter
            </v-btn>

            <v-btn
              v-if="!item.postedReceiptNo"
              outlined
              small
              color="secondary"
              @click="pay(item)"
            >
              Pay Now
              <v-icon color="secondary" right small> mdi-arrow-right </v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>

        <div v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                small
                :disabled="!isProfileComplete"
                @click="submit(item)"
              >
                {{ profile.ProfileComplete ? "re-" : "" }}Submit
                <v-icon right>mdi-send</v-icon>
              </v-btn>
            </template>
            <span>{{
              isProfileComplete
                ? "Submit your application"
                : "Complete your profile"
            }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card class="pa-7" tile elevation="5">
        <v-card-title> Verify Otp </v-card-title>

        <v-card-text>
          <v-alert
            outlined
            v-if="$store.getters['Application/alert'].status"
            dense
            border="left"
            :type="
              $store.getters['Application/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
            class="mb-10"
          >
            {{ $store.getters["Application/alert"].message }}
          </v-alert>
        </v-card-text>

        <v-form ref="">
          <v-card-text>
            <v-text-field
              dense
              outlined
              label="Otp Code"
              placeholder="Enter Otp code"
              ref="otp_code"
              :rules="rules.required"
              v-model="otp_code"
            />
          </v-card-text>
        </v-form>

        <v-card-actions>
          <v-btn @click="dialog = false" text> Close </v-btn>

          <v-spacer />

          <v-btn @click="VerifyAppOTP" color="primary"> Verify </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Checkout />
  </v-container>
</template>

<script>
import { appUrl } from "../../../environment/environment";
import DateMixin from "../../../mixins/DateMixin";
import { AuthService } from "../../auth";
import { EventBus } from "../../../utils/eventBus";
import Checkout from "@kinetics254/paygate/src/views/Checkout.vue";

export default {
  name: "ApplicationList",
  mixins: [DateMixin],
  components: {
    Checkout,
  },
  data: function () {
    return {
      headers: [
        {
          text: "Application No",
          align: "start",
          value: "Code",
        },
        { text: "Applied Category", value: "AppliedCategory" },
        { text: "Amount", value: "Amount" },
        { text: "Application Date", value: "ApplicationDateTime" },
        { text: "Status", value: "status" },
        // { text: "Stage", value: "reviewStage" },
        {
          text: `Feedback from ${process.env.VUE_APP_ORGANIZATION}`,
          value: "membershipComments",
          sortable: false,
        },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      dialog: false,
      otp_code: "",
      applicationData: {},
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Application/getApplications");
    });
  },

  mounted() {
    EventBus.$on("application-submitted", () => {
      this.$router.push({
        name: "applicationDocument",
        params: { code: this.applicationDetails.Code },
      });
    });
    EventBus.$on("applicationOtp-success", () => {
      this.dialog = true;
    });
    EventBus.$on("applicationverify-success", () => {
      this.dialog = false;
    });
  },

  computed: {
    application() {
      return this.$store.getters["Application/applications"];
    },

    applicationDocument() {
      return this.$store.getters["Application/document"];
    },

    isProfileComplete() {
      let isComplete = true;
      this.$store.getters["Checklist/checklist"].forEach((check) => {
        if (!check.pass) isComplete = false;
      });

      return isComplete;
    },

    profile() {
      return AuthService.user.profile;
    },
    user() {
      return AuthService.user;
    },
    member() {
      return AuthService.user.profile.memberInformation.shift();
    },
    rules() {
      return {
        required: [(v) => !!v || "Field is required"],
      };
    },
  },

  methods: {
    filterComments(comments) {
      return comments.filter((comment) => !comment.hiddenFromApplicant);
    },
    pay: function (data) {
      let payload = {
        payerID: data.ProfileID,
        sourceDocumentNo: data.Code,
        description: "Application Payment",
        amount: data.Amount,
        accountNo: "",
        success_url: new URL(
          this.$router.resolve({ name: "ApplicationList" }).href,
          appUrl
        ),
        fail_url: window.location.href,
      };

      payload = btoa(JSON.stringify(payload));
      // window.location.href = new URL(`?payload=${payload}`, payGateUrl);
      this.$checkout.pay(payload);
    },

    submit(item) {
      this.$confirm.show({
        title: `Submit Application ?`,
        text: `By clicking submit, you confirm that you have read the terms and conditions, that you understand them and that you agree to be bound by them.`,
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        onConfirm: () => {
          this.applicationData = { ...item };
          const data = {
            userId: this.user.id,
          };
          this.$store.dispatch("Application/submitandsendOtp", data);

          // this.$store.dispatch("Application/submit", { ...item });
          // this.$store.dispatch(
          //   "Application/getApplicationsDocument",
          //   item.Code
          // );
          // this.applicationDetails = item;
        },
      });
    },
    VerifyAppOTP() {
      const data = {
        otp_code: { otp_code: this.otp_code },
        appData: { ...this.applicationData },
      };
      this.$store.dispatch("Application/verifySubmitOtp", data);
      this.applicationDetails = this.applicationData;
    },
  },
};
</script>

<style scoped></style>
