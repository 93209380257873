<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
  >
    <v-row>
      <v-col md="4" sm="12" offset-md="4">
        <v-card class="pa-5">
          <v-img
            :src="`data:image/png;base64,${$store.getters['Setup/companyInformation'].logo}`"
            height="100"
            contain
            class="mb-5"
          />

          <v-card-title class="text-h4 d-flex flex-row justify-center">
            SET PASSWORD
          </v-card-title>
          <v-card-subtitle class="text-center">
            Set a new password
          </v-card-subtitle>
          <v-form v-model="isValid" ref="ResetPassform">
            <v-card-text class="text-justify mt-4 pb-0">
              <v-alert
                outlined
                v-if="$store.getters['Auth/alert'].status"
                dense
                border="left"
                :type="
                  $store.getters['Auth/alert'].status === 'success'
                    ? 'success'
                    : 'error'
                "
                class="mb-10"
              >
                {{ $store.getters["Auth/alert"].message }}
              </v-alert>

              <v-text-field
                dense
                outlined
                type="password"
                label="New Password"
                placeholder="Enter a secure password"
                v-model="formData.password"
                ref="password"
                :rules="rules.password"
              />

              <v-text-field
                dense
                outlined
                type="password"
                label="Confirm Password"
                placeholder="Enter Password again"
                v-model="formData.confirmPassword"
                ref="confirmPassword"
                :rules="rules.confirmPassword"
              />
            </v-card-text>

            <v-card-actions class="">
              <v-row>
                <v-col cols="12">
                  <v-btn @click="reset" color="primary" block> Reset </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "../../../router/RouterMixin";

export default {
  name: "Password",
  mixins: [RouterMixin],
  data: function () {
    return {
      isValid: false,
      formData: {
        password: "",
        confirmPassword: "",
      },
    };
  },

  computed: {
    rules() {
      return {
        password: [
          (v) => !!v || "Password required",
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#~^()`.,;:{}_/%*?&])[A-Za-z\d@$!#~^()`.,;:{}_/%*?&]{8,16}$/.test(
              v
            ) ||
            "Password should contain: Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character(@$!#~^()`.,;:{}_/%*?&)",
        ],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) => v === this.formData.password || "Password does not match",
        ],
      };
    },
  },

  methods: {
    reset: function () {
      if (!this.isValid) {
        this.$refs.ResetPassform.validate();
      } else {
        this.formData.token = this.$route.params.code;
        this.$store.dispatch("Auth/resetPassword", { ...this.formData });
      }
    },
  },
};
</script>

<style scoped>
html{
  font-size: 100%;
}
@media (max-width: 37.5em){
  .v-text-field input{
    font-size: .8rem;
  }
}

</style>
