<template>
  <v-container class="fill-height mt-5 d-flex flex-row align-center">
    <v-row>
      <v-col
        md="8"
        offset-md="2"
        sm="12"
        class="d-flex flex-column justify-center"
      >
        <h1 class="text-center">
          SEARCH
          {{ caption.login_caption ? caption.login_caption.toUpperCase() : "" }}
        </h1>

        <v-text-field
          label=""
          color="primary"
          v-model="term"
          placeholder="Search by Member No / ID or Passport / Email / Member Name"
          outlined
          @click:append="search"
          @keyup.enter="search"
          class="search-member"
          hide-details
          elevation-12
        >
          <template v-slot:append-outer>
            <v-btn
              depressed
              @click="search"
              color="primary"
              class="ma-0 search-btn text-capitalize"
              :loading="loading"
            >
              {{
                $vuetify.breakpoint.mobile
                  ? ""
                  : `Search ${
                      caption.login_caption
                        ? caption.login_caption.toUpperCase()
                        : ""
                    }`
              }}
              <v-icon class="mx-2">mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <v-card elevation="0" class="mt-3">
          <v-card-title>
            Search Results
            <v-spacer />
            <span>Found {{ items.length }} of total results</span>
          </v-card-title>

          <v-card-title class="d-flex" v-if="items.length !== 0">
            <v-row>
              <v-col>
                <v-autocomplete
                  outlined
                  dense
                  label="Gender"
                  :items="Genders"
                  v-model="Gender"
                >
                </v-autocomplete>
              </v-col>

              <v-col>
                <v-autocomplete
                  outlined
                  dense
                  :items="membershipCategories"
                  :item-text="(item) => item.Description"
                  :item-value="(item) => item.Code"
                  v-model="Category"
                  label="Category"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-btn @click="clear" color="primary" dark ripple>
                  <v-icon left>mdi-close</v-icon>
                  clear Search
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-list two-line>
            <v-list-item-group
              v-model="selected"
              active-class="secondary--text"
            >
              <template v-for="(item, index) in results">
                <v-list-item
                  :key="item.ProfileID"
                  :to="{
                    name: 'searchCard',
                    params: { code: encodeRoute(item.ProfileID) },
                  }"
                >
                  <template v-slot:default>
                    <v-list-item-content>
                      <v-list-item-title class="secondary--text">
                        {{
                          `${getProfileTitle(item.profileTitle)} ${
                            item.FirstName
                          } ${item.SecondName} ${item.LastName}`
                        }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="text--primary">
                        <span class="text--primary">
                          Member No:
                          {{ memberNumberMask(item.MemberNo) }} , Category:
                          {{ item.MemberShipType }}
                        </span>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-if="showWorkPlace">
                        <span v-for="(c, j) in item.contacts" :key="j">
                          Work Place: {{ getCurrentWorkPlace(item.workexp) }},
                          Address:
                          {{ c.Address }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <div class="d-flex">
                        <v-chip
                          x-small
                          color="primary"
                          class="mx-2"
                          v-if="item.memberInformation.length > 0"
                        >
                          {{ item.Gender }}
                        </v-chip>
                        <!-- <v-chip
                            x-small
                            dark
                            v-if="item.memberInformation.length > 0"
                            :color="
                              item.memberInformation[0].Status === 'Active'
                                ? 'success'
                                : 'danger'
                            "
                          >
                            {{ item.memberInformation[0].Status }}
                          </v-chip> -->
                      </div>
                      <v-icon color="grey lighten-1"> mdi-star-outline </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < items.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>

          <v-card-actions>
            <v-spacer />
            <v-pagination
              v-model="page"
              :length="pageLength"
              :disabled="results.length === 0"
            />
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col md="8" offset-md="2" sm="12">
        Summary
        <v-row>
          <v-col
            class="d-flex"
            cols="12"
            md="4"
            v-for="(stat, i) in stats"
            :key="i"
          >
            <v-sheet outlined rounded>
              <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title class="overline mb-2 font-weight-bold">{{
                    i
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    class="overline"
                    v-for="(num, j) in stats[i]"
                    :key="j"
                  >
                    {{ j }} : <span class="primary--text">{{ num }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "../../../router/RouterMixin";
import SetupMixin from "../../setup/SetupMixin";
import MaskMixin from "../../../mixins/MaskMixin";

export default {
  name: "SearchList",
  mixins: [RouterMixin, SetupMixin, MaskMixin],
  data: function () {
    return {
      selected: 0,
      term: "",
      page: 1,
      items_per_page: 20,
      Genders: ["Male", "Female"],
      Gender: null,
      Category: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Search/getStats");
      v.getSetups(["getMembershipCategories"]);
      v.$store.dispatch("Admin/getSettings");
    });
  },

  computed: {
    showWorkPlace() {
      return this.settings.minor_settings
        ? JSON.parse(this.settings.minor_settings).showWorkPlace
        : false;
    },
    pageLength() {
      return parseInt(this.items.length / this.items_per_page) + 1;
    },

    stats() {
      return this.$store.getters["Search/stats"];
    },

    items() {
      return this.$store.getters["Search/profiles"] || [];
    },

    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },

    caption() {
      return this.settings && this.settings.minor_settings
        ? JSON.parse(this.settings.minor_settings)
        : "";
    },

    results() {
      if (this.Gender && this.Category) {
        return this.pagination(
          this.items.filter((item) => {
            return (
              item.Gender === this.Gender &&
              item.MemberShipType === this.Category
            );
          })
        );
      } else if (this.Gender && !this.Category) {
        return this.pagination(
          this.items.filter((item) => {
            return item.Gender === this.Gender;
          })
        );
      } else if (!this.Gender && this.Category) {
        return this.pagination(
          this.items.filter((item) => {
            return item.MemberShipType === this.Category;
          })
        );
      } else {
        return this.pagination(this.items);
      }
    },

    loading() {
      return this.$store.getters["Search/loading"];
    },
  },

  methods: {
    getProfileTitle(profileTitle) {
      return profileTitle.length > 0
        ? profileTitle.map((el) => el.title).join(", ")
        : "";
    },
    search: function () {
      this.Gender = null;
      this.Category = null;
      if (this.term) this.$store.dispatch("Search/search", this.term.trim());
    },
    clear() {
      this.Gender = null;
      this.Category = null;
      this.page = 1;
    },
    pagination(items) {
      return items.filter((res) => {
        return (
          items.indexOf(res) >=
            this.page * this.items_per_page - this.items_per_page &&
          items.indexOf(res) <= this.page * this.items_per_page - 1
        );
      });
    },
    getCurrentWorkPlace(currentWorkPlace) {
      if (currentWorkPlace !== undefined) {
        currentWorkPlace = currentWorkPlace.find((el) => el.Current);
        return currentWorkPlace !== undefined
          ? currentWorkPlace.NameOfFirm
          : "";
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
@import url("../styles.css");
</style>
