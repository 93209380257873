import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"py-2 px-2",attrs:{"elevation":"0","min-height":"90vh"}},[_c(VCardTitle,[_c(VToolbar,{attrs:{"elevation":"1"}},[_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")]),_vm._v(" Go back ")],1),_c(VToolbarTitle,{staticClass:"ml-2"},[_c('span',{staticClass:"headline"},[_vm._v("Customer Statement")])])],1)],1),_c(VCardText,{staticClass:"pl-6"},[_c('iframe',{staticStyle:{"border":"none !important"},attrs:{"src":("data:application/pdf;base64," + _vm.statement),"width":"1550","height":"1000","title":"Customer Statement report"}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }