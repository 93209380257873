import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-5"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VSelect,{ref:"filterYear",attrs:{"dense":"","outlined":"","label":"Filter by year","placeholder":"Filter by year","clearable":"","items":_vm.yearList.years,"width":"10"},on:{"change":_vm.filter,"click:clear":function($event){return _vm.fetch()}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c(VIcon,{staticClass:"mb-n6",attrs:{"size":"16"}},[_vm._v(" mdi-filter ")])]},proxy:true}]),model:{value:(_vm.filterYear),callback:function ($$v) {_vm.filterYear=$$v},expression:"filterYear"}})],1)],1),_c(VDataTable,{staticClass:"mt-5 elevation-1",attrs:{"headers":_vm.headers,"items":this.filterYear ? this.filteredCpds : _vm.cpdPoints,"sort-by":"calories","dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"outlined":"","x-small":"","color":"secondary","href":_vm.editEvent(_vm.encodeRoute(item.bookingNo)),"target":"_blank"}},[_vm._v(" view "),_c(VIcon,{attrs:{"color":"secondary","right":"","small":""}},[_vm._v(" mdi-arrow-right ")])],1)]}},{key:"body.append",fn:function(){return [_c('tr',{staticStyle:{"height":"60px","color":"white","background-color":"black"}},[_c('td'),_c('td',{attrs:{"colspan":"4","rowspan":"4"}},[_c('span',{staticClass:"text-body-1"},[_vm._v(" Total CPD Acquired")])]),_c('td'),_c('td',{attrs:{"colspan":"4","rowspan":"4"}},[_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.total)+" ")])])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }