import call from "../../service/http";
import ApplicationConstant from "./ApplicationConstant";
import { EventBus } from "../../utils/eventBus";

export default {
  namespaced: true,
  state: {
    applications: [],
    document: "",
    certificate: "",
    rejectionLetter: "",
    acceptanceLetter: "",
    applicationReceipt: "",
    Proforma: "",
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    SET_APPLICATIONS: (state, payload) => {
      state.applications = payload;
    },
    SET_DOCUMENT: (state, payload) => {
      state.document = payload;
    },
    SET_CERTIFICATE: (state, payload) => {
      state.certificate = payload;
    },
    SET_REJECTION_LETTER: (state, payload) => {
      state.rejectionLetter = payload;
    },
    SET_ACCEPTANCE_LETTER: (state, payload) => {
      state.acceptanceLetter = payload;
    },
    SET_APPLICATION_RECEIPT: (state, payload) => {
      state.applicationReceipt = payload;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    applications: (state) => state.applications,
    document: (state) => state.document,
    certificate: (state) => state.certificate,
    alert: (state) => state.alert,
    rejectionLetter: (state) => state.rejectionLetter,
    acceptanceLetter: (state) => state.acceptanceLetter,
  },
  actions: {
    getApplications: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", ApplicationConstant.application)
        .then((res) => {
          commit("SET_APPLICATIONS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getApplicationsDocument: ({ commit }, code) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", ApplicationConstant.document(code))
        .then((res) => {
          console.log(res.data.data);
          commit("SET_DOCUMENT", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    getCertificate: ({ commit }, code) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", ApplicationConstant.certificate(code))
        .then((res) => {
          console.log(res.data.data);
          commit("SET_CERTIFICATE", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    checklist: ({ commit }, category) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", ApplicationConstant.checklist(category))
        .then((res) => {
          commit("Checklist/SET_CHECKLIST", res.data.data, { root: true });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    submit: ({ commit, dispatch }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", ApplicationConstant.submit, payload)
        .then(() => {
          EventBus.$emit("application-submitted");
          dispatch("Auth/user", null, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getRejectionLetter: ({ commit }, code) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", ApplicationConstant.rejectionLetter(code))
        .then((res) => {
          console.log(res.data.data);
          commit("SET_REJECTION_LETTER", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    getAcceptanceLetter: ({ commit }, code) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", ApplicationConstant.acceptanceLetter(code))
        .then((res) => {
          console.log(res.data.data);
          commit("SET_ACCEPTANCE_LETTER", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    verifySubmitOtp: ({ commit, dispatch }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", ApplicationConstant.verifyOtp, data.otp_code)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "success",
            message: "Successfully verified Otp",
          });
          dispatch("submit", data.appData);
          dispatch("getApplicationsDocument", data.appData.Code);
          EventBus.$emit("applicationverify-success");
        })
        .catch((err) => {
          commit("SET_VERIFY", "failed");
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    submitandsendOtp: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", ApplicationConstant.sendOtp(data.userId))
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("applicationOtp-success");
        })
        .catch((err) => {
          console.log(err);
          commit("SET_VERIFY", "failed");
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
